<template>
  <div @click="$emit('show-modal', task)">
    <el-card
      class="task-card-wrap"
      :style="priorityLine"
    >
      <div class="task-card">
        <p>
          {{ task.title }}
        </p>

        <div
          class="dropdown"
          @click.stop
        >
          <el-dropdown>
            <span class="el-dropdown-link">
              <el-button
                slot="reference"
                icon="el-icon-more"
                size="mini"
                :style="{ border: 'none' }"
                circle
              />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <p @click.prevent="$emit('show-modal-task')">
                  Edit
                </p>
              </el-dropdown-item>
              <el-dropdown-item v-if="!isArchive">
                <p @click.prevent="$emit('archive-task')">
                  Archive
                </p>
              </el-dropdown-item>
              <el-dropdown-item>
                <p
                  style="color: #d31d36"
                  @click.prevent="$emit('delete-task')"
                >
                  Delete
                </p>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>

      <div
        v-if="task.picture"
        class="picture-wrap"
        :style="bgPicture"
      />

      <div
        v-if="task.subtasks.items.length && task.subtasks.items"
        class="progress-wrap"
      >
        <progress-bar
          :show-progress="true"
          :percentage="percentage"
          :width="11"
        />
      </div>

      <div class="users-tags-wrap">
        <users-avatars
          v-show="task.users"
          :size="'small'"
          :hidden-users="true"
          :users="task.users"
        />

        <div v-if="task.tags.items.length">
          <el-tag
            v-for="tag of tagsArr"
            :key="tag.uuid"
            size="mini"
          >
            {{ tag.name }}
          </el-tag>
          <el-tooltip
            v-if="task.tags.items.length > 2"
            placement="bottom"
          >
            <el-tag size="mini">
              <i class="el-icon-more-outline" />
            </el-tag>

            <ul slot="content">
              <li
                v-for="tag of tagsHiddenTitles"
                :key="tag.uuid"
              >
                {{ tag.name }}
              </li>
            </ul>
          </el-tooltip>
        </div>
      </div>

      <div class="footer-card">
        <div
          v-if="task.subtasks.items.length && task.subtasks.items"
          class="footer-card-count"
        >
          <i class="el-icon-finished" />
          <span>{{ completesTasks }}/{{ task.subtasks.items.length }}</span>
        </div>
        <div
          v-if="task.picture"
          class="footer-card-count"
        >
          <i class="el-icon-paperclip" />
          <span>{{ task.picture.length }}</span>
        </div>

        <span
          v-if="task.dueDate"
          class="text-date"
        >{{ dueDateText }}</span>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'KanbanTaskCard',
  components: {
    UsersAvatars: () => import('./UsersAvatars.vue'),
    ProgressBar: () => import('./ProgressBar.vue'),
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    isArchive: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    priorityLine() {
      if (this.task.priority) {
        return { 'border-left': `5px solid ${this.task.priority.color}` };
      }

      return '';
    },
    bgPicture() {
      if (this.task.picture) {
        return { 'background-image': `url(${this.task.picture[0].url})` };
      }

      return '';
    },
    tagsArr() {
      return this.task.tags.items.length > 2
        ? this.task.tags.items.slice(0, 2)
        : this.task.tags.items;
    },
    tagsHiddenTitles() {
      return this.task.tags.items.length > 2
        ? this.task.tags.items.slice(2)
        : null;
    },
    percentage() {
      return +((100 * this.completesTasks) / this.task.subtasks.items.length).toFixed(0);
    },
    completesTasks() {
      return this.task.subtasks.items.filter(t => t.checked === true).length;
    },
    dueDateText() {
      if (this.task.completed) {
        return 'Done';
      } if (!this.task.dueDate) {
        return '';
      }

      const time = new Date(this.task.dueDate) - new Date().getTime();
      let number;
      let message;

      switch (true) {
      case time < 60000 && time > 1:
        message = 'Due 1 minute';
        break;
      case time < 3.6e+6 && time >= 60000:
        number = (time / 60000).toFixed(0);

        message = `Due ${number} minutes`;
        break;
      case time < 7.2e+6 && time >= 3.6e+6:
        message = 'Due 1 hour';
        break;
      case time < 8.64e+7 && time >= 7.2e+6:
        number = (time / 3.6e+6).toFixed(0);

        message = `Due ${number} hours`;
        break;
      case time < 17.28e+7 && time >= 8.64e+7:
        number = (time / 7.2e+6).toFixed(0);
        message = 'Due 1 day';
        break;
      case time >= 17.28e+7:
        number = (time / 8.64e+7).toFixed(0);

        message = `Due ${number} days`;
        break;
      default:
        message = 'Overdue';
      }

      return message;
    },
  },
};
</script>

<style
  lang="stylus"
  rel="stylesheet/css"
  scoped
>
::v-deep {
  .el-card__body {
    padding: 0;
    color: #525252;
  }
}
.el-tooltip__popper ul {
  margin: 0;
  list-style-type: none;
  padding: 1px;
}
.new-task-wrap {
  min-height: 10px;
  cursor: default;
}
  .line {
    height: 2px;
    background-color: #8a8a8a;
    margin: 7.5px 0;
  }
.task-card-wrap {
  padding: 10px;
  background: #ffffff;
  box-shadow: unset;
  position: relative;
}
.task-card-wrap p {
  width: 93%;
}
  .task-card-wrap:hover .dropdown{
    display: block;
  }
    .task-card {
      display: flex;
    }
.el-tag {
  margin-right: 4px;
}
.dropdown {
  position: absolute;
  display: none;
  right: 8px;
  top: 6px;
}
.picture-wrap {
  margin-top: 5px;
  height: 60px;
  width: 100%;
  background-size: cover;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: center center;
}
.progress-wrap {
  padding: 6px 0;
}
.users-tags-wrap {
  display: flex;
  justify-content: space-between
  align-items: center;
  flex-wrap: wrap;
}
.users-tags-wrap div {
    padding-bottom: 4px;
}
.footer-card {
  display: flex;
  column-gap: 15px;
}
  & span {
    justify-self: end;
    margin-left: auto;
  }
.footer-card-count i {
  margin-right: 3px;
}
.nav-icon {
  margin-right : 5px;
}
</style>
